import React, { FunctionComponent, useEffect } from "react"
import SEO from "../components/seo/seo"
import "../assets/styles/page/docvideopage.scss"
import RichTextRenderer from "../components/rich-text-handler/rich-text-handler"
import Videocard from "../components/VideoCard"
import YouTube, { YouTubeProps } from "react-youtube"
import Layout from "../components/layout"
import { Link } from "gatsby"
import Tweetbutton from "../components/tweetbutton"
import Facebookshare from "../components/facebookshare"

interface VideopageProps {
  pageContext: {
    edge: {
      metaTitle: string;
      videoId: string;
      videoTitle: string;
      aboutVideo: { json: any };
      videoCategory: string;
      _id: string;
      videoThumbnail: { url: string };
    };
    data: any[];
  };
}

const Videopage: FunctionComponent<VideopageProps> = ({ pageContext }) => {
  const { metaTitle, videoId, videoTitle, aboutVideo, videoCategory, _id, videoThumbnail } = pageContext.edge

  const VideoArray = pageContext.data

  const onPlayerReady: YouTubeProps["onReady"] = event => {
    event.target.pauseVideo()
  }

  const opts: YouTubeProps["opts"] = {
    height: "390",
    width: "640",
    playerVars: {
      autoplay: 0
    }
  }

  return (
    <>
      <SEO title={videoTitle} description={metaTitle} image={videoThumbnail.url} />
      <Layout>
        <div className="video--content--container">
          <div className="video--thumbnail--wrapper">
            <div className="video--thumbnail">
              <YouTube videoId={`${videoId}`} opts={opts} onReady={onPlayerReady} iframeClassName="Youtube--video" />
            </div>
            <div className="video--title--wrapper">
              <div className="video--text--inner">
                <h2 className="video--title--headtext">{videoTitle}</h2>
                <p className="video--title--subtext">{metaTitle}</p>
                <p className="meta">{videoCategory}</p>
              </div>
            </div>
          </div>
          <div className="video--about--content">
            <p className="video--about--content--text">
              <RichTextRenderer content={aboutVideo.json} />
            </p>
          </div>
          <div className="share--article--media">
            <Facebookshare />
            <Tweetbutton titleOfArticle={videoTitle} />
          </div>
          <div className="upnext--video--wrapper">
            <div id="next-text">
              <h2>Up Next</h2>
            </div>
            <div className="article--list">
              {VideoArray.slice(0, 4)
                .filter((e: { _id: any }) => e._id !== _id)
                .map(
                  (video: {
                    videoThumbnail: any
                    videoTitle: any
                    _id: any
                    metaTitle: any
                    videoCategory: any
                    slug: any
                  }) => {
                    const { videoThumbnail, videoTitle, _id, metaTitle, videoCategory, slug } = video

                    return (
                      <Link to={`/doc/article/doc-videos/${slug}`} className="artile--card--wrapper--2" key={_id}>
                        <Videocard
                          key={_id}
                          thumbnail={videoThumbnail.url}
                          videotitle={videoTitle}
                          videodes={metaTitle}
                          meta={videoCategory}
                        />
                      </Link>
                    )
                  }
                )}
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default Videopage
